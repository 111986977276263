import React, { useMemo, useState } from "react";
import {
  FindMeIcon,
  DeviceUnpairIcon,
  NewConnectedIcon,
  LatestActionIcon,
} from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import { Popconfirm, Modal, Button } from "antd";
import { getConfirmationMessage } from "./DeviceInventoryModal";
import RemoteControlLayout from "./RemoteControlLayout";
import { RCButtons } from "./RemoteControlLayout";
import {
  Access,
  checkRoleAccess,
} from "@commscopemycloud/humaui/Utilities/AccessControl";

const RemoteControlModalInfo = ({
  device,
  hubRecord,
  performAction,
  actionsData,
  hideModal,
  showModal,
  ConfirmationModal,
  isActionModalVisible,
}) => {
  const userRole = useUserRole();

  const allowShowOrientation = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );

  const allowManageAction = useMemo(
    () =>
      hasStaffPrivileges(userRole) &&
      hubRecord?.rolename?.toLowerCase() === "manage",
    [userRole, hubRecord]
  );
  const allowFindMeAction = useMemo(
    () => ["manage", "care"].includes(hubRecord?.rolename?.toLowerCase()),
    [userRole, hubRecord]
  );

  const showActionsBloc =
    (allowFindMeAction || hasStaffPrivileges(userRole)) &&
    device?.paired_status !== "UNPAIRED";

  const handleUnpair = (device) => {
    let data = {
      ...actionsData.unpair,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data, null);
    hideModal("unpair_rcu");
  };

  const handleFindMe = (device) => {
    let data = {
      ...actionsData.find_me,
      deviceUuid: hubRecord?.deviceuuid,
    };
    performAction(data, null);
    hideModal("find_me");
  };

  // Extract the last orientation from device_motion_events
  const lastMotionEvent = device?.device_motion_events
    ? JSON.parse(device.device_motion_events).slice(-1)[0]
    : null;

  const lastOrientation = lastMotionEvent?.orientation || "-";

  return (
    <div className="rc400-bloc-bottom">
      <div className="bloc-left">
        <div className="tv-configuration-block">
          <div className="configuration-block-container">
            <div className="custom-title-label">TV Configuration</div>
            <div className="configuration-details-block">
              <div className="code">
                <span className="label">Brand: </span>
                <span className="value">
                  {device?.device_brand_id ? device?.device_brand_id : "-"}
                </span>
              </div>
              <div className="code">
                <span className="label">Remote IR code: </span>
                <span className="value">
                  {device?.device_codeset_id ? device?.device_codeset_id : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        {showActionsBloc && (
          <div className="actions-bloc">
            <div className="actions-bloc-container">
              <div className="actions-main">
                <div className="title">Actions</div>
                <div className="description-container">
                  {allowFindMeAction && (
                    <div className="find-me">
                      {` “Find Me”`}
                      <span className="find-me-desc">{` : activates a beeping for 30 seconds on the remote`}</span>
                    </div>
                  )}
                  {device?.paired_status !== "UNPAIRED" &&
                    hasStaffPrivileges(userRole) && (
                      <div className="unpair">
                        {` “Unpair”`}
                        <span className="unpair-desc">{` : unpair the sensor by clicking on the unpair button`}</span>
                      </div>
                    )}
                </div>
              </div>
              <div className="action-bottom">
                {allowFindMeAction && (
                  <div
                    className="find-me-button"
                    onClick={() => showModal("find_me")}
                  >
                    <FindMeIcon className="icon" />
                    <div className="label">Find Me</div>
                  </div>
                )}
                {device?.paired_status !== "UNPAIRED" &&
                  hasStaffPrivileges(userRole) && (
                    <div
                      className="unpair-button"
                      onClick={() => showModal("unpair_rcu")}
                    >
                      <DeviceUnpairIcon
                        className="unpair-icon"
                        strokeColor="#005d98"
                        fillColor="#0072BC"
                      />
                      <div className="label">Unpair</div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
        {/* <div
         className="update-bloc"
         style={{ height: showActionsBloc ? "auto" : "100%" }}
       >
         <div className="main-info">
           <div className="update-main-info">
             <div className="update-title-desc">
               <div className="title">Updates</div>
             </div>
             <div className="label-container">
               <div className="title">Software version:</div>
               <div className="software-version-container">
                 <div className="value">{device?.softwareversion || "-"}</div>
               </div>
             </div>
           </div>
           <div className="history-info">
             <div className="synch-info">
               <DeviceUnpairIcon
                 className="unpaired-icon"
                 strokeColor="#ffa500"
                 fillColor="#FFA500"
               />
               <div className="synch-title ">
                 {`Synchronising: `}
                 <span className="synch-desc">{` -`}</span>
               </div>
             </div>
             <div className="update-info">
               <NewConnectedIcon className="icon" />
               <div className="update-title">
                 Last update -/- -:-:
                 <span className="update-desc">{` -`}</span>
               </div>
             </div>
           </div>
         </div>
         <div className="button-info">
           <div
             className="check-updates-label"
             style={{ visibility: allowManageAction ? "visible" : "hidden" }}
           >
             Check for Updates
           </div>
         </div>
       </div> */}
      </div>
      <div
        className={`device-bloc ${allowShowOrientation ? "large" : "small"}`}
      >
        <div className="top-info">
          <div className="desc-info">
            HomeSight Remote control (Model{" "}
            {device?.device_name ? device?.device_name : "-"}){" "}
          </div>
          {allowShowOrientation && (
            <div className="latest-action">
              <LatestActionIcon className="latest-action-icon" />
              <div className="latest-action-title">
                {` Latest action :`}
                <span className="latest-action-subtitle">
                  {device?.device_key_name
                    ? `${device.device_key_name} pressed `
                    : "-"}
                  <span className="highlight-text">
                    {device?.device_key_action
                      ? `“${device.device_key_action}”`
                      : "-"}
                  </span>
                </span>
              </div>
            </div>
          )}
          <div className="remote-container">
            <RemoteControlLayout
            // buttonSelected={RCButtons.RCMicrophone}
            // allowShowOrientation={allowShowOrientation}
            />
          </div>
          {allowShowOrientation && (
            <div className="subtitle-info">
              <div className="current-orientation-label">
                Current orientation :
                <span className="current-orientation-subtitle">
                  {` ${lastOrientation}`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        visible={isActionModalVisible.find_me}
        title={
          <span className="custom-modal-title">
            {getConfirmationMessage(actionsData.find_me.title)}
          </span>
        }
        description={actionsData.find_me.description}
        onConfirm={() => handleFindMe(device)}
        onCancel={() => hideModal("find_me")}
      />

      <ConfirmationModal
        visible={isActionModalVisible.unpair_rcu}
        title={
          <span className="custom-modal-title">
            {getConfirmationMessage(actionsData.unpair.title)}
          </span>
        }
        description={actionsData.unpair.description}
        onConfirm={() => handleUnpair(device)}
        onCancel={() => hideModal("unpair_rcu")}
      />
    </div>
  );
};

export default RemoteControlModalInfo;