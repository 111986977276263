import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  postalCodeStatus,
  filterEventsData,
  sortEventsData,
  mapEventData,
  determineTempTrend,
  checkAllDevicesProcessed,
  processWeatherData,
} from "./EnvironmentalUtils";

export const useUserAccessories = (hubRecord) => {
  const [userAccessoriesDeviceuuid, setUserAccessoriesDeviceuuid] =
    useState(null);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const trans = useSelector(translator);

  useEffect(() => {
    const fetchUserAccessories = () => {
      const errorCallback = (error) => {
        console.error(error);
      };
      const successCallback = (data) => {
        const deviceUuids = data["user_accessories"]
          .filter((device) => device.modelnumber === "EM200")
          .reduce((acc, device) => {
            acc[device.deviceuuid] = {
              location: device.location,
              connectionStatus: device.device_connected ? "CONNECTED" : "DISCONNECTED",
              batteryLevel: device.device_battery_level,
            };
            return acc;
          }, {});
        setUserAccessoriesDeviceuuid(deviceUuids);
      };

      try {
        userEventsApi.getUserAccessories(
          hubRecord.useruuid,
          { eventtypes: ["environment_status"] },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    };

    fetchUserAccessories();
  }, [hubRecord, userEventsApi, trans]);

  return userAccessoriesDeviceuuid;
};

export const useUserEvents = (
  hubRecord,
  userAccessoriesDeviceuuid,
  tempUnit,
  setTempUnit
) => {
  const [userEventsData, setUserEventsData] = useState(null);
  const [dataProcessed, setDataProcessed] = useState(false);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const trans = useSelector(translator);

  const processUserEventsData = async (deviceuuid, eventsData) => {
    if (!eventsData) return;

    setUserEventsData((currentData) => {
      const filteredEventsData = filterEventsData(eventsData);
      const sortedEventsData = sortEventsData(filteredEventsData);
      const eventData = sortedEventsData.map(mapEventData)[0];

      if (eventData !== undefined) {
        eventData.trend = determineTempTrend(sortedEventsData);
        !tempUnit && setTempUnit(eventData?.tempUnit);
      }

      const updatedData = {
        ...currentData,
        [deviceuuid]: {
          data: eventData,
          location: userAccessoriesDeviceuuid[deviceuuid].location,
        },
      };

      if (checkAllDevicesProcessed(updatedData, userAccessoriesDeviceuuid)) {
        setDataProcessed(true);
      }

      return updatedData;
    });
  };

  useEffect(() => {
    if (userAccessoriesDeviceuuid) {
      Object.keys(userAccessoriesDeviceuuid).forEach((deviceuuid) => {
        const fetchLatestUserEvents = (deviceuuid) => {
          const errorCallback = (error) => {
            console.error(error);
          };
          const successCallback = (data) => {
            processUserEventsData(deviceuuid, data["user_events"]);
          };

          try {
            userEventsApi.getLatestUserEvents(
              hubRecord.useruuid,
              { deviceuuid: [deviceuuid], eventtypes: ["environment_status"] },
              apiCallback({
                translator: trans,
                failCallback: errorCallback,
                errorCallback,
                successCallback,
              })
            );
          } catch (error) {
            errorCallback(error);
          }
        };

        fetchLatestUserEvents(deviceuuid);
      });
      if (!Object.keys(userAccessoriesDeviceuuid).length) {
        setDataProcessed(true);
      }
    }
  }, [userAccessoriesDeviceuuid, hubRecord, userEventsApi, trans]);

  return { userEventsData, dataProcessed };
};

export const useWeatherData = (userInfo, hubRecord, tempUnit) => {
  const [outdoorWeather, setOutdoorWeather] = useState({});
  const [outdoorWeatherFetched, setOutDoorWeatherFetched] = useState(false);
  const [postalcodeStatus, setpostalcodeStatus] = useState(null);
  const handleInvalidPostalCode = (status = null) => {
    status === postalCodeStatus.empty
      ? setpostalcodeStatus(postalCodeStatus.empty)
      : setpostalcodeStatus(postalCodeStatus.invalid);
    setOutDoorWeatherFetched(true);
  };
  const weatherApi = useSelector((state) => state.apis.weatherApi);
  const trans = useSelector(translator);

  useEffect(() => {
    const fetchOutdoorData = () => {
      const errorCallback = (error) => {
        console.error(error);
        if (
          error.response?.body?.info?.message?.includes(
            postalCodeStatus.noLocationKey
          )
        ) {
          handleInvalidPostalCode(postalCodeStatus.invalid);
        }
      };
      const successCallback = (data) => {
        processWeatherData(data, tempUnit, setOutdoorWeather);
        setpostalcodeStatus(postalCodeStatus.valid);
        setOutDoorWeatherFetched(true);
      };

      try {
        weatherApi.getCurrentConditions(
          { useruuid: hubRecord.useruuid },
          apiCallback({
            translator: trans,
            failCallback: errorCallback,
            errorCallback,
            successCallback,
          })
        );
      } catch (error) {
        errorCallback(error);
      }
    };

    if (!userInfo.postalcode) {
      handleInvalidPostalCode(postalCodeStatus.empty);
    } else {
      fetchOutdoorData();
    }
  }, [hubRecord, tempUnit, weatherApi, trans]);

  return { postalcodeStatus, outdoorWeather, outdoorWeatherFetched };
};
