import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Divider, Popconfirm, Table, message, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import {
  CallNewIcon,
  CareIcon,
  ContactImage,
  SocialIcon,
  ResendEmailIcon,
  ContactsIcon,
  ManageNewIcon,
  CloseIcon,
  AddPlusIcon,
  ScheduleAddIcon,
  EditNewIcon,
  PendingIcon,
} from "../Common/Icons";
import {
  formatFullNameDisplay,
  isCare,
  isManage,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import {
  KnownActionsList,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import useNotification from "../Hooks/useNotification";
import {
  filterNotifications,
  processNotifications,
} from "../HubDetails/HubDetailsUtils";
import moment from "moment";
import { StarTwoTone } from "@ant-design/icons";

const formatContacts = (contacts) =>
  contacts.map((c) => {
    return { key: c.useruuid, ...c };
  });

const ListContacts = (props) => {
  console.debug("ListContacts props", props);
  const {
    hubRecord,
    contacts,
    fetchingContacts,
    onRemove,
    onActionClick,
    openSubMenu = false,
  } = props;

  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const userApi = useSelector((state) => state.apis.userApi);
  const contactList = useMemo(() => formatContacts(contacts), [contacts]);
  const permissions = useSelector(getPermissions());
  const [favorites, setFavorites] = useState([]);
  const [getFavorites, setGetFavorites] = useState([]);

  const allowCreateEvent = useMemo(
    () =>
      isActionAllowed(
        hubRecord?.rolename,
        KnownActionsList.create_events,
        permissions
      ),
    [hubRecord, permissions]
  );
  const allowCallContact = useMemo(
    () =>
      isActionAllowed(
        hubRecord?.rolename,
        KnownActionsList.call_contacts,
        permissions
      ),
    [hubRecord, permissions]
  );
  const allowManageContact = useMemo(
    () =>
      isActionAllowed(
        hubRecord?.rolename,
        KnownActionsList.manage_contacts,
        permissions
      ),
    [hubRecord, permissions]
  );

  const onResendEmailHandler = (contactuseruuid) => {
    const errorCallback = (error) => {
      const msg = `Error Sending Email details`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      const msg = `Email Sent`;
      console.debug("onSuccess:", msg, data);
      message.success(msg);
      onActionClick(null, null, true)();
    };
    try {
      const user = {
        useruuid: contactuseruuid,
        updateprofile: true,
      };
      userApi.updateUser(
        user,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const sortedContactsList = useMemo(() => {
    return [...contactList].sort(objectSort("firstname"));
  }, [contactList]);

  // Notifications related code
  const [contactNotifInfo, setContactNotifInfo] = useState({});
  const startdatetime = useMemo(() => moment().subtract(3, "day").format(), []);
  const isCareOrManageRole = isCare(hubRecord) || isManage(hubRecord);

  const [notifications] = useNotification({
    useruuid: hubRecord.useruuid,
    startdatetime: startdatetime,
  });

  const ongetFavoriteContact = () => {
    const errorCallback = (error) => {
      const msg = `Error Feching Favorite Contact details`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      console.info("Contacts: fetch favorite result", data);
      // Set only the first user's useruuid as favorite
      const firstFavorite = data.contacts.length > 0 ? data.contacts[0].useruuid : null;
      setGetFavorites(firstFavorite ? [firstFavorite] : []);
      setFavorites(firstFavorite ? [firstFavorite] : []);
    };
    try {
      const useruuid = hubRecord.useruuid;
      userApi.getFavoriteContacts(
        useruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };
  
  const handleFavoriteChange = (useruuid) => {
    setFavorites((prevFavorites) => {
      if (prevFavorites.includes(useruuid)) {
        // Removing from favorites
        onUpdateFavoriteContacts([{ useruuid, favorite: false, removeContact: true }]);
        return prevFavorites.filter((id) => id !== useruuid);
      } else if (prevFavorites.length === 0) {
        // Setting as the only favorite
        onUpdateFavoriteContacts([{ useruuid, favorite: true }]);
        return [useruuid];
      } else {
        // Toggling favorites: remove the old favorite and set the new one
        const previousFavorite = prevFavorites[0];
        onUpdateFavoriteContacts([
          { useruuid: previousFavorite, favorite: false },
          { useruuid, favorite: true },
        ]);
        return [useruuid];
      }
    });
  };

  const onUpdateFavoriteContacts = (contacts) => {
    const errorCallback = (error) => {
      const msg = `Error Updating Favorite contacts`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      const msg = `Favorite Contact Updated Successfully`;
      const removeMsg = `Removed from Favorite Contacts Successfully`;            
      console.debug("onSuccess Favorite Contact Updated Successfully:", msg, data);
      if(contacts[0].removeContact) {
        message.error(removeMsg);
      } else {
        message.success(msg);
      }            
      ongetFavoriteContact();
    };
    try {
      const useruuidToUpdate = hubRecord.useruuid;
      userApi.updateFavoriteContacts(
        useruuidToUpdate,
        { contacts },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    ongetFavoriteContact();
  }, []);

  useEffect(() => {
    if (
      isActionAllowed(
        hubRecord?.rolename,
        KnownActionsList.call_history,
        permissions
      )
    ) {
      const filteredList = filterNotifications(notifications);
      const processedList = processNotifications(filteredList, hubRecord);
      setContactNotifInfo(processedList);
    }
  }, [hubRecord, permissions, notifications, sortedContactsList]);

  const renderUserType = (hub) =>
    isManage(hub) ? (
      <>
        <ManageNewIcon />
        <span className="type-text">{trans("MANAGE")}</span>
      </>
    ) : isCare(hub) ? (
      <>
        <CareIcon />
        <span className="type-text">{trans("CARE")}</span>
      </>
    ) : isSocial(hub) ? (
      <>
        <SocialIcon />
        <span className="type-text">{trans("SOCIAL")}</span>
      </>
    ) : (
      <div></div>
    );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        let mIcon = null;
        if (record.useruuid in contactNotifInfo) {
          mIcon = contactNotifInfo[record.useruuid].icon;
        }
        return (
          <div className="user-info-container">
            <div className="icons-container">
              {record.profilePic64 ? (
                <img
                  src={record.profilePic64}
                  className="user-profile-pic"
                  alt=""
                />
              ) : (
                <ContactImage className="user-profile-pic" />
              )}
              {mIcon && <div>{mIcon}</div>}
              {getFavorites.includes(record.useruuid) && record.emailVerified === true && (
              <div>
                <StarTwoTone className="favorite-icon" twoToneColor="#ffd700" />
              </div>
            )}
            </div>
            <label className={`${
                getFavorites.includes(record.useruuid)
                  ? "favorite-name name"
                  : "name"
              }`}>
              {formatFullNameDisplay(record?.firstname, record?.lastname)}
            </label>
          </div>
        );
      },
    },
    {
      title: "Relationship",
      dataIndex: "targetrelationship",
      className: "relationship-column",
    },
    {
      title: "Role",
      dataIndex: "rolename",
      render: (_, record) => (
        <div className="usertype-container">
          <Divider type="vertical" className="divider" />
          {renderUserType(record)}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "emailVerified",
      render: (emailVerified) => (
        <div className="item-counter entity-actions-container ">
          {emailVerified === false ? <PendingIcon /> : null}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="entity-actions-container zoom-control">
          <Divider type="vertical" className="divider" />
          {allowCreateEvent && record.emailVerified === false ? (
            <Tooltip title={trans("RESENDEMAIL")} placement="bottom">
              <div className="entity-action">
                <ResendEmailIcon
                  className="resend-icon"
                  onClick={() => {
                    onResendEmailHandler(record.useruuid);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="entity-action">
              <StarTwoTone className={favorites.includes(record.useruuid) ? 'favorite' : 'notfavorite'}
              onClick={isCareOrManageRole ? () => handleFavoriteChange(record.useruuid) : null}
              />
            </div>
          )}
          {allowCreateEvent && record.emailVerified === true ? (
            <Tooltip title={trans("SCHEDULEEVENT")} placement="bottom">
              <div className="entity-action">
                <ScheduleAddIcon
                  className="schedule-icon"
                  onClick={onActionClick(
                    Actions.addEvent,
                    record,
                    openSubMenu ? true : false
                  )}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="empty-action" />
          )}
          {!record.providerName &&
          record.useruuid !== currentUser.useruuid &&
          allowCallContact &&
          record.emailVerified === true &&
          record.callpermission ? (
            <Tooltip title={trans("VIDEOCALL")} placement="bottom">
              <div
                className="entity-action"
                onClick={() =>
                  dispatch(startVideoCall({ callees: [record], hubRecord }))
                }
              >
                <CallNewIcon className="call-icon" />
              </div>
            </Tooltip>
          ) : (
            <div className="empty-action" />
          )}
          {allowManageContact ? (
            <Tooltip title={trans("EDIT")} placement="bottom">
              <div className="entity-action">
                <EditNewIcon
                  className="edit-icon"
                  onClick={onActionClick(Actions.editContact, record)}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="empty-action" />
          )}
          {allowManageContact || record.useruuid === currentUser.useruuid ? (
            <Popconfirm
              placement="topRight"
              okText={trans("YES")}
              cancelText={trans("NO")}
              onConfirm={() => {
                onRemove(record.useruuid);
              }}
              title={trans("AREYOUSURETOREMOVECONTACT")}
            >
              <Tooltip title={trans("REMOVE_L")} placement="bottom">
                <div className="entity-action">
                  <CloseIcon className="remove-icon" />
                </div>
              </Tooltip>
            </Popconfirm>
          ) : (
            <div className="empty-action" />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="contacts-list-wrapper page-content-wrapper custom-page-wrapper">
      <div className="page-wrapper">
        <header className="page-header-container">
          <label className="page-header">
            <div>
              <ContactsIcon className="addcontacts-icon" />
            </div>
            <div className="title-text">{trans("CONTACTS")}</div>
            <div></div>
          </label>
        </header>
        <div className="contacts-info">
          {allowManageContact ? (
            <div className="add-contact-icon">
              <Tooltip title={trans("ADDCONTACT")}>
                <AddPlusIcon
                  onClick={onActionClick(Actions.inviteContact, null)}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="empty-action" />
          )}
          <div className="contacts-count">
            {sortedContactsList.length} {trans("TOTAL")}
          </div>
        </div>
        <Table
          showHeader={false}
          dataSource={sortedContactsList}
          columns={columns}
          loading={fetchingContacts}
          bordered={false}
          pagination={false}
          className="entity-table contacts-table"
        />
      </div>
    </div>
  );
};

ListContacts.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  hubRecord: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  onActionClick: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ListContacts;
