import React, { useState } from "react";
import { Button, Modal } from "antd";
import { CloseIcon, DeviceInventoryIcon } from "../Common/Icons";
import DeviceInventoryInfoCard from "./DeviceInventoryInfoCard";
import {
  DeviceConnectionIconInfo,
  DeviceSensorInfo,
  StatusInfo,
} from "./DeviceInventoryList";
import EM200ModalInfo from "./EM200ModalInfo";
import HC200ModalInfo from "./HC200ModalInfo";
import RemoteControlModalInfo from "./RemoteControlModalInfo";

export const getConfirmationMessage = (actionTitle) => {
  return `Are you sure you want perform action: ${actionTitle}?`;
};

const ConfirmationModal = ({
  visible,
  onConfirm = () => {},
  onCancel,
  title,
  description,
}) => (
  <Modal
    title={title}
    open={visible}
    onOk={onConfirm}
    onCancel={onCancel}
    closable={false}
    className="pop-up-modal-container"
    footer={null}
  >
    <p className="custom-modal-description">{description}</p>
    <div className="custom-footer">
      <Button className="custom-no-button" onClick={onCancel}>
        <span className="custom-no-label">No</span>
      </Button>
      <Button
        className="custom-yes-button"
        onClick={() => {
          onConfirm();
          onCancel(); // Close the modal after confirmation
        }}
      >
        <span className="custom-yes-label">Yes</span>
      </Button>
    </div>
  </Modal>
);

const DeviceInventoryModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  device,
  selectedDeviceInfo,
  deviceStatus,
  timezone,
  connectionStatus,
  hubRecord,
  performAction,
  actionsData,
  accessoriesData,
}) => {
  const isEM200 = device?.modelnumber === "EM200";
  const isHC200 = device?.modelnumber === "HC200";
  const isTVDevice = accessoriesData.find((d) => d.modelnumber === "tv");

  const [isActionModalVisible, setIsActionModalVisible] = useState({
    unpair_rcu: false,
    find_me: false,
    unpair_em200: false,
    factory_reset_hc200 : false,
    restart_hc200 : false,
    check_for_updates_hc200 : false,
  });

  const showModal = (modalKey) => {
    setIsActionModalVisible((prevState) => ({
      ...prevState,
      [modalKey]: true,
    }));
  };

  const hideModal = (modalKey) => {
    setIsActionModalVisible((prevState) => ({
      ...prevState,
      [modalKey]: false,
    }));
  };

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      centered
      className="device-modal-container"
      style={{ minWidth: "800px" }} // Added min-width
    >
      <div className="modal-container">
        <div className="device-modal-header-container">
          <div className="device-header-icon-title">
            <div className="device-header-icon">
              <DeviceInventoryIcon
                style={{ fill: "#113E57", width: "29px", height: "32px" }}
              />
            </div>
            <div className="device-header-title">Device details</div>
          </div>
          <div className="close-button" onClick={handleCancel}>
            <CloseIcon className="close-icon" />
            <span className="close-label">Close</span>
          </div>
        </div>

        <div className="device-card-container">
          <div className="device-container">
            <div className="info-block-container">
              <DeviceSensorInfo device={device} />
              <div className="connection-info-container">
                {device?.modelnumber === "HC200" ? (
                  <StatusInfo
                    lastConnectTime={device?.lastconnecttime}
                    device={device}
                    timezone={timezone}
                  />
                ) : (
                  <div className="battery-info-container">
                    <span className="battery-level-label">Battery level:</span>
                    <span className="battery-level">
                      {device?.device_battery_level != null ||
                      device?.device_battery_level != undefined
                        ? `${device?.device_battery_level}%`
                        : "-"}
                    </span>
                  </div>
                )}
                <DeviceConnectionIconInfo
                  device={device}
                  deviceStatus={deviceStatus}
                  connectionStatus={connectionStatus}
                />
              </div>
            </div>
            <DeviceInventoryInfoCard
              device={device}
              isViewMoreClicked={true}
              selectedDeviceInfo={selectedDeviceInfo}
            />
          </div>

          <div className="device-info-container">
            {isEM200 ? (
              <EM200ModalInfo
                device={device}
                performAction={performAction}
                actionsData={actionsData}
                hubRecord={hubRecord}
                ConfirmationModal={ConfirmationModal}
                showModal={showModal}
                hideModal={hideModal}
                isActionModalVisible={isActionModalVisible}
              />
            ) : isHC200 ? (
              <HC200ModalInfo
                device={device}
                hubRecord={hubRecord}
                performAction={performAction}
                actionsData={actionsData}
                isTVDevice={isTVDevice}
                ConfirmationModal={ConfirmationModal}
                showModal={showModal}
                hideModal={hideModal}
                isActionModalVisible={isActionModalVisible}
              />
            ) : (
              <RemoteControlModalInfo
                device={device}
                hubRecord={hubRecord}
                performAction={performAction}
                actionsData={actionsData}
                ConfirmationModal={ConfirmationModal}
                showModal={showModal}
                hideModal={hideModal}
                isActionModalVisible={isActionModalVisible}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeviceInventoryModal;
