import React from "react";
import "./DeviceInventory.less";
import { NewDeviceIcon, NewLocationIcon } from "../Common/Icons";
import iconNew from "../Common/Icons/iconNew.svg";

const DeviceInventoryInfoCard = ({
  device,
  isViewMoreClicked,
  selectedDeviceInfo,
}) => {
  const renderExpandedInfo = () => (
    <div className="expanded-device-info">
      {selectedDeviceInfo.map((info) => (
        <div key={info.key} className="info-row">
          <div className="info-key">{info.key}:</div>
          <div className="info-value">{info.value}</div>
        </div>
      ))}
    </div>
  );

  const renderCompactInfo = () => (
    <div className="info-row">
      <div className="device-detail-icon">
        <img src={iconNew} alt="New Icon" />
      </div>
      <div className="device-detail-serial-number">
        <NewDeviceIcon className="new-device-icon" />
        <div className="serial-number-label">Serial Number: </div>
        <div className="serial-number">
          {device?.device_serial_number || "-"}
        </div>
      </div>
      <div className="device-detail-location">
        <NewLocationIcon className="new-location-icon" />
        <div className="location-label">Location Name: </div>
        <div className="location">{device?.location || "-"}</div>
      </div>
    </div>
  );

  return (
    <div className="small-device-card">
      <div className="general-info">General Information</div>
      {isViewMoreClicked ? renderExpandedInfo() : renderCompactInfo()}
    </div>
  );
};

export default DeviceInventoryInfoCard;
