import React, { useMemo } from "react";
import {
  NewRestartIcon,
  NewFactoryResetIcon,
  DeviceUnpairIcon,
  NewConnectedIcon,
  NewCheckForUpdatesIcon,
} from "../Common/Icons";
import { useUserRole, hasStaffPrivileges } from "./helpers";
import NetworkingDetailsBlock from "./NetworkingDetailsBlock";
import HC200icon from "../Common/Icons/HC200icon.svg";
import { Popconfirm } from "antd";
import { getConfirmationMessage } from "./DeviceInventoryModal";

const HC200ModalInfo = ({
  device,
  hubRecord,
  performAction,
  actionsData,
  isTVDevice,
  hideModal,
  showModal,
  ConfirmationModal,
  isActionModalVisible,
}) => {
  const userRole = useUserRole();
  const allowManageAction = useMemo(
    () =>
      hasStaffPrivileges(userRole) &&
      hubRecord?.rolename?.toLowerCase() === "manage",
    [userRole, hubRecord]
  );

  const showActionButtons =
    (allowManageAction || hasStaffPrivileges(userRole)) &&
    device?.paired_status !== "UNPAIRED";

  const handleRestart = () => {
    const data = {
      ...actionsData.reboot,
      deviceUuid: device.deviceuuid,
    };
    performAction(data);
    hideModal("restart_hc200");
  };

  const handleFactoryReset = () => {
    const data = {
      ...actionsData.reset,
      deviceUuid: device.deviceuuid,
    };
    performAction(data);
    hideModal("factory_reset_hc200");
  };

  const handleCheckForUpdates = () => {
    const data = {
      ...actionsData.check_for_updates,
      deviceUuid: device.deviceuuid,
    };
    performAction(data);
    hideModal("check_for_updates_hc200");
  };

  // Define the constant map
  const tvInfoMap = isTVDevice
    ? [
        {
          key: "Manufacturer",
          value: isTVDevice?.device_manufacture_name || "-",
        },
        { key: "Model", value: isTVDevice?.device_tv_model || "-" },
        {
          key: "Connected to",
          value: isTVDevice?.device_connected_hdmi_port || "-",
        },
        { key: "Resolution", value: isTVDevice?.device_resolution || "-" },
        { key: "CEC", value: isTVDevice?.device_cec_status || "-" },
        { key: "Control mode", value: isTVDevice?.device_learning_type || "-" },
      ]
    : [];

  return (
    <>
      <div className="hc200-container-row-1">
        {showActionButtons && (
          <div className="actions-row">
            <div className="hc200-container">
              <div className="hc200-actions-block">
                <div className="action-label">Actions</div>
                <div className="action-description-container">
                  {hasStaffPrivileges(userRole) && (
                    <div className="action-description-row-1">
                      <span className="description-row-1-label">{` “Factory Reset” `}</span>
                      <span className="description-row-1-description">
                        : initiates a Factory Reset by clicking on the button
                      </span>
                    </div>
                  )}
                  {allowManageAction && (
                    <div className="action-description-row-2">
                      <span className="description-row-2-label">{` “Restart” `}</span>
                      <span className="description-row-2-description">
                        : restart the Smart Camera by clicking on the button
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="action-bottom-buttons">
                <div className="action-buttons">
                  {hasStaffPrivileges(userRole) && (
                    <span>
                      <div onClick={() => showModal("factory_reset_hc200")}>
                        <span>
                          <NewFactoryResetIcon className="new-factory-reset-icon" />
                        </span>
                      </div>
                    </span>
                  )}
                  <div className="button-container">
                    <div
                      className="button-block"
                      style={{
                        visibility: allowManageAction ? "visible" : "hidden",
                        display: allowManageAction ? "block" : "none",
                      }}
                    >
                      <span className="check-for-updates-label">
                        <div
                          onClick={() => showModal("check_for_updates_hc200")}
                        >
                          <span>
                            <NewCheckForUpdatesIcon className="new-check-for-updates-icon" />
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                  {allowManageAction && (
                    <span>
                      <div onClick={() => showModal("restart_hc200")}>
                        <span>
                          <NewRestartIcon className="new-restart-icon" />
                        </span>
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="hc200-container-row-2">
          <div className="hc200-column-1">
            <div className="hc200-actions-container-column-1">
              <div className="hc200-networking-container">
                <div className="hc200-networking-block">
                  <div className="networking-label">
                    <span className="label">Networking</span>
                  </div>
                  <NetworkingDetailsBlock
                    WANType={device?.device_primary_wan}
                    device={device}
                  />
                </div>
              </div>
              {/* <div className="hc200-updates-container">
                <div className="update-container-content">
                  <div className="updates-block">
                    <div className="update-label-container">
                      <span className="label">Updates</span>
                    </div>
                    <div className="software-version-block">
                      <div className="sw-version-label">Software Version: </div>
                      <div className="software-version-container">
                        <div className="sw-version-value">
                          {device?.softwareversion || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="history-details">
                     <div className="history-detail-1">
                      <DeviceUnpairIcon
                        className="unpaired-icon"
                        strokeColor="#ffa500"
                        fillColor="#FFA500"
                      />
                      <div className="block-1">
                        <div className="key-1">
                          Synchronising:
                          <span className="value-1">{` -`}</span>
                        </div>
                      </div>
                    </div> 
                    <div className="history-detail-2">
                      <NewConnectedIcon className="connected-icon" />
                      <div className="block-2">
                        <div className="key-2">
                          Last update {formattedDate}:
                          <span className="value-2">{` -`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <div
                    className="button-block"
                    style={{
                      visibility: allowManageAction ? "visible" : "hidden",
                    }}
                  >
                    <span className="check-for-updates-label">
                      <Popconfirm
                        title={getConfirmationMessage(
                          actionsData.check_for_updates.title
                        )}
                        description={actionsData.check_for_updates.description}
                        onConfirm={() => handleCheckForUpdates(device)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <span>Check For Updates </span>
                      </Popconfirm>
                    </span>
                  </div>
                </div>
              </div> */}
              <div className="hc200-tv-container">
                <div className="hc200-tv-block">
                  <div className="tv-label">
                    <span className="label">TV information</span>
                  </div>
                  <div className="tv-details-container">
                    {tvInfoMap.map((item, index) => (
                      <div key={index} className="tv-info-row">
                        <span className="tv-info-key">{item.key}:</span>
                        <span className="tv-info-value"> {item.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hc200-column-2">
            <div className="hc200-block">
              <div className="hc200-label">
                HomeSight Smart Camera (Model{" "}
                {device?.modelnumber ? device?.modelnumber : "-"})
              </div>
              <div className="hc200-device-icon">
                <img src={HC200icon} />
              </div>
            </div>
          </div>
        </div>

        <ConfirmationModal
          visible={isActionModalVisible.check_for_updates_hc200}
          title={
            <span className="custom-modal-title">
              {getConfirmationMessage(actionsData.check_for_updates.title)}
            </span>
          }
          description={actionsData.check_for_updates.description}
          onConfirm={() => handleCheckForUpdates(device)}
          onCancel={() => hideModal("check_for_updates_hc200")}
        />
        <ConfirmationModal
          visible={isActionModalVisible.restart_hc200}
          title={
            <span className="custom-modal-title">
              {getConfirmationMessage(actionsData.reboot.title)}
            </span>
          }
          description={actionsData.reboot.description}
          onConfirm={() => handleRestart(device)}
          onCancel={() => hideModal("restart_hc200")}
        />
        <ConfirmationModal
          visible={isActionModalVisible.factory_reset_hc200}
          title={
            <span className="custom-modal-title">
              {getConfirmationMessage(actionsData.reset.title)}
            </span>
          }
          description={actionsData.reset.description}
          onConfirm={() => handleFactoryReset(device)}
          onCancel={() => hideModal("factory_reset_hc200")}
        />
      </div>
    </>
  );
};

export default HC200ModalInfo;
