/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Skeleton, Tooltip } from "antd";
import {
  Actions,
  DateFormat,
  PhotoResolution,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import MainPage from "../../MainPage";
import {
  ContactIcon1,
  HSCalendar,
  AddPlusIcon,
  CheckIcon,
  NewScheduleIcon,
} from "../Common/Icons";
import {
  getDateFormat,
  getTimeFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import {
  getProfilePicUrl,
  getUsername,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getContacts } from "./Contacts";
import { HSDatePicker } from "../Common/HSWidget";
import { UserImage } from "./UserImage";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { useDispatch, useSelector } from "react-redux";
import {
  Access,
  KnownActionsList,
  checkRoleAccess,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getNotificationMiniBannerMessage } from "@commscopemycloud/humaui/Store/notificationStore";
import moment from "moment";
import useSchedule from "../Hooks/useSchedule";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CHECK_INTERVAL_MS = 20000;
const SHOW_STATUS_BEFORE_MINUTES = 15;
const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;
const SHOW_JOIN_STATUS_TILL_MINUTES = ONGOING_MEETING_DISPLAY_TIME_HOURS * 60;

const ListSchedule = (props) => {
  const {
    onlyContent,
    parentBread,
    selectedDate,
    setSelectedDate,
    scheduleList,
    fetchingScheduleList,
    onActionClick,
    contacts,
    userInfo,
    userHubsData,
    rolename,
    hubRecord,
    openSubMenu = false,
    loginUserTimeformat,
  } = props;
  const trans = useSelector(translator);
  const dispatch = useDispatch();
  const resourcesMap = useSelector(getProfilePics());
  const header = parentBread || [];
  const userTimezone = LocalTimezone;
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const permissions = useSelector(getPermissions());

  const [, scheduleListData, fetchScheduleData] = useSchedule();
  const [buttonStatus, setButtonStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [eventInfo, setEventInfo] = useState(null);
  const webSocketMessage = useSelector(getNotificationMiniBannerMessage);

  const allowAddEvent = useMemo(
    () =>
      (rolename === undefined ||
        isActionAllowed(
          rolename,
          KnownActionsList.create_events,
          permissions
        )) &&
      hubRecord,
    [hubRecord]
  );
  const allowAddTask = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );


  const formatList = (scheduleList) => {
    const selectedDateTime = new Date(selectedDate);

    let filteredList = scheduleList.filter((event) => {
      const eventDate = new Date(event.event_timestamp);
      return eventDate.getTime() >= selectedDateTime.getTime();
    });

    const list = filteredList.reduce((acc, event) => {
      const dateObj = getDateFormat(event.event_timestamp, userTimezone);
      const date = dateObj.date;

      if (!acc[date]) {
        acc[date] = [];
      }

      const duplicate = acc[date].some(
        (e) => e.schedule_eventuuid === event.schedule_eventuuid
      );

      if (!duplicate) {
        acc[date].push(event);
      }

      return acc;
    }, {});

    const sortedList = Object.values(list).sort((a, b) => {
      return new Date(a[0]?.event_timestamp) - new Date(b[0]?.event_timestamp);
    });
    return sortedList;
  };

  const formattedList = useMemo(() => formatList(scheduleList), [scheduleList]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const contactImage = (contact) => {
    let displayName = getUsername(contact);
    const url = getProfilePicUrl(
      contact.useruuid,
      resourcesMap,
      PhotoResolution.R64
    );
    return <UserImage name={displayName} url={url} />;
  };

  const renderDateListItem = (item) => {
    let dateObj = getDateFormat(item.event_timestamp, userTimezone);
    return (
      <div
        key={dateObj.dayDateformat}
        className={
          dateObj.dayDateformat.startsWith("Today")
            ? "schedule-list-date-newui schedule-list-today-newui"
            : "schedule-list-date-newui"
        }
      >
        <div className="schedule-date-newui">{dateObj.dayDateformat}</div>
      </div>
    );
  };

  const renderInvitedList = (invitedUseruuid) => {
    let invitedContacts = getContacts(invitedUseruuid, [
      ...(contacts ?? []),
      ...(userHubsData ?? []),
      ...(userInfo ? [userInfo] : []),
    ]);

    const count = invitedContacts.length;

    const invitedContactsData = invitedContacts
      .slice(0, 9)
      .map((contact, index) => (
        <div key={index} className="user-image-container">
          {contact && contactImage(contact)}
        </div>
      ));

    return { invitedContactsData, count };
  };

  const getDateTimeFromWebSocket = (wsEvent) => {
    if (wsEvent == null) return;
    let dateTime = null;
    if ("event_timestamp" in wsEvent)
      dateTime = moment(+wsEvent.event_timestamp);
    else {
      dateTime = moment(wsEvent.event_time, "HH:mm");
      dateTime.add(dateTime.utcOffset(), "minutes");
    }
    return dateTime;
  };

  const checkForOnGoingEvent = (scheduleListData, wsEvent) => {
    let now = moment().subtract(ONGOING_MEETING_DISPLAY_TIME_HOURS, "hour");
    let schEvent = scheduleListData.reverse().find((event) => {
      let time = moment(event.display_event_time, "HH:mm:ss");
      if ("display_day" in event) {
        if (event.display_day !== now.format("dddd")) return;
      }
      if (time.isAfter(now)) return event;
    });
    let event = schEvent;

    if (wsEvent != null) {
      let dateTime = getDateTimeFromWebSocket(wsEvent);
      if (schEvent === undefined) {
        if (dateTime.isAfter(now)) event = wsEvent;
      } else {
        if (dateTime.isAfter(moment(schEvent.display_event_time, "HH:mm:ss")))
          event = wsEvent;
      }
    }
    return event;
  };

  useEffect(() => {
    // Find scheduled event after current time if any
    let now = moment();
    let schEvent = scheduleListData.find((event) => {
      let time = moment(event.display_event_time, "HH:mm:ss");
      if ("display_day" in event) {
        if (event.display_day !== now.format("dddd")) return;
      }
      if (time.isAfter(now)) return event;
    });
    const wsEvent = webSocketMessage?.schedule_event;
    let event = schEvent;

    // Check for web socket message cases compared to scheduled event
    if (wsEvent != null) {
      if (wsEvent.action?.includes("delete")) {
        setEventInfo(null);
        fetchScheduleData(useruuid, new Date(new Date().setHours(0, 0, 0, 0)));
        return;
      }
      let dateTime = getDateTimeFromWebSocket(wsEvent);
      if (
        dateTime != null &&
        dateTime.isAfter(now) &&
        (schEvent === undefined ||
          moment(schEvent.display_event_time, "HH:mm:ss").isAfter(dateTime))
      )
        event = wsEvent;
    }

    if (event == null) {
      event = checkForOnGoingEvent(scheduleListData, webSocketMessage);
      if (event == null) return;
    }

    const eventInfo = {
      eventInfo: event,
      eventId: event.schedule_eventuuid,
      time: "",
      label: event.event_name,
    };
    if ("display_event_time" in event) {
      let dateTime = moment(event.display_event_time, "HH:mm:ss");
      eventInfo.time = dateTime.format("h:mm A");
    } else if ("event_timestamp" in event) {
      let dateTime = moment(+event.event_timestamp);
      eventInfo.time = dateTime.format("h:mm A");
    } else {
      let time = moment(event.event_time, "HH:mm");
      time.add(time.utcOffset(), "minutes");
      eventInfo.time = time.format("h:mm A");
    }
    setEventInfo(eventInfo);
  }, [webSocketMessage, scheduleListData]);

  const handleButtonClick = (item) => {
    if (item.event_type?.toLowerCase() === sessionType.zoom) {
      window.open(item.meeting_url);
    } else {
      dispatch(
        startVideoCall({
          meeting_id: item.schedule_eventuuid,
          eventInfo: item,
        })
      );
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonStatus((prevStatus) => !prevStatus);
    }, 2000);
    // Immediately trigger the button status change once
    setButtonStatus((prevStatus) => !prevStatus);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const renderButtonStatus = (item) => {
    if (item.event_type?.toLowerCase() === "reminder") {
      return null;
    }
    if (!item.invited_contacts.includes(useruuid)) {
      return null;
    }
    let itemTime;
    if ("display_event_time" in item) {
      let dateTime = moment(item.display_event_time, "HH:mm:ss");
      itemTime = dateTime.format("h:mm A");
    } else if ("event_timestamp" in item) {
      let dateTime = moment(+item.event_timestamp);
      itemTime = dateTime.format("h:mm A");
    } else {
      let time = moment(item.event_time, "HH:mm");
      time.add(time.utcOffset(), "minutes");
      itemTime = time.format("h:mm A");
    }

    const now = moment();
    const eventTime = moment(itemTime, "h:mm A");
    const diff = eventTime.diff(now, "minute");

    if (diff > 0 && diff <= SHOW_STATUS_BEFORE_MINUTES) {
      return (
        <div className="status" onClick={() => handleButtonClick(item)}>
          About to Start
        </div>
      );
    } else if (diff <= 0 && diff >= -SHOW_JOIN_STATUS_TILL_MINUTES) {
      return (
        <div className="status" onClick={() => handleButtonClick(item)}>
          Join
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    let interval;
    if (eventInfo !== null) {
      updateStatus();
      interval = setInterval(() => {
        updateStatus();
      }, CHECK_INTERVAL_MS);
    }
    return () => {
      if (interval !== undefined) clearInterval(interval);
    };
  }, [eventInfo]);

  const updateStatus = () => {
    const now = moment();
    const eventTime = moment(eventInfo.time, "h:mm A");
    const diff = eventTime.diff(now, "minute");
    if (diff === SHOW_STATUS_BEFORE_MINUTES) setStatus("About to start");
    else if (diff < 1 && diff >= -SHOW_JOIN_STATUS_TILL_MINUTES)
      setStatus("Join");
    else if (diff < -SHOW_JOIN_STATUS_TILL_MINUTES) setEventInfo(null);
    else setStatus(null);
  };

  useEffect(() => {
    const nextDate = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
      date: moment().date() + 1,
    });
    setTimeout(() => {
      fetchScheduleData(useruuid, new Date(new Date().setHours(0, 0, 0, 0)));
    }, nextDate.diff(moment()) + 2000);
  }, []);

  const renderNoList = () => {
    let currentDate = getDateFormat(moment(), userTimezone);
    return (
      <div
        className={
          currentDate.dayDateformat.startsWith("Today")
            ? "schedule-list-date-newui schedule-list-today-newui"
            : "schedule-list-date-newui"
        }
      >
        <div className="schedule-date-newui">{currentDate.dayDateformat}</div>
        {formattedList.length === 0 && (
          <div className="note-box no-events">
            <CheckIcon style={{ marginRight: "10px" }} />
            <span>{trans("NOUPCOMINGEVENTS")}</span>
          </div>
        )}
      </div>
    );
  };

  const renderScheduleList = useCallback(() => {
    return (
      <div className="schedule-list-container-newui">
        <div className="schedule-actions-newui">
          {!fetchingScheduleList && allowAddEvent && (
            <Tooltip title="ADD EVENT">
              <div className="add-event-icon">
                <AddPlusIcon
                  onClick={onActionClick(Actions.addEvent, userInfo)}
                />
              </div>
            </Tooltip>
          )}
          {!fetchingScheduleList && allowAddTask && (
            <Tooltip title={trans("ADDTASK")}>
              <div>
                <AddPlusIcon
                  className="add-task-icon"
                  onClick={onActionClick(Actions.addTask, userInfo)}
                />
              </div>
            </Tooltip>
          )}

          <div className="date-picker-container">
            <HSDatePicker
              DateFormat={DateFormat}
              timeZone={userTimezone}
              defaultValue={selectedDate}
              onDateSelect={handleDateSelect}
            />
          </div>
        </div>
        {fetchingScheduleList ? (
          <div className="schedule-container-newui skeleton-container-newui">
            <Skeleton active avatar paragraph={false} />
          </div>
        ) : formattedList.length === 0 ? (
          <>{renderNoList()}</>
        ) : (
          formattedList.map((list, index) => (
            <div key={"schedule_day_" + index} className="schedule-list-data">
              {renderDateListItem(list[0])}
              {list.map((item) => (
                <div
                  key={item.schedule_eventuuid}
                  className="schedule-container-newui"
                >
                  <div className="schedule-list-row1-newui">
                    <div
                      onClick={onActionClick(Actions.startEvent, item)}
                      className="container-1"
                    >
                      <div
                        title={userTimezone}
                        className="schedule-eventtime-newui"
                      >
                        {(loginUserTimeformat && loginUserTimeformat?.is24hoursformat) ?
                          getTimeFormat(item.event_timestamp, userTimezone, loginUserTimeformat?.is24hoursformat) :
                          getTimeFormat(item.event_timestamp, userTimezone, loginUserTimeformat?.is24hoursformat)}
                      </div>
                      {item.recurring ? (
                        <HSCalendar />
                      ) : (
                        <div
                          className="empty-action"
                          style={{ width: "30px", marginLeft: "10px" }}
                        ></div>
                      )}
                      <div
                        className={`${
                          item.event_type?.toLowerCase() === sessionType.zoom
                            ? "zoomeventtype"
                            : item.event_type?.toLowerCase() ===
                              sessionType.reminder
                            ? "remindereventtype"
                            : "hseventype"
                        }`}
                      >
                        {item.event_type?.toLowerCase() === sessionType.zoom
                          ? trans("ZOOMCALL")
                          : item.event_type?.toLowerCase() ===
                            sessionType.reminder
                          ? "Calendar Reminder"
                          : trans("VIDEOCALL")}
                      </div>
                      <div className="schedule-eventname-newui">
                        {item.event_name}
                      </div>
                    </div>
                    <div className="container-2">
                      {item?.schedule_eventuuid &&
                        moment(item?.event_timestamp).isSame(
                          moment(),
                          "day"
                        ) && <div>{renderButtonStatus(item)}</div>}
                      {/* <Divider className="schedule-divider" type="vertical" />
                      <div className="actions-container">
                        {item.event_originator_id === useruuid ||
                        (rolename !== undefined &&
                          isActionAllowed(
                            rolename,
                            KnownActionsList.manage_schedule,
                            permissions
                          )) ? (
                          <div
                            onClick={onActionClick(Actions.editEvent, item)}
                            className="action"
                          >
                            <Tooltip title={trans("EDIT")}>
                              <div>
                                <EditIcon />
                              </div>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="empty-action" />
                        )}
                      </div> */}
                    </div>
                  </div>
                  <div className="schedule-list-row2-newui">
                    <div>
                      <ContactIcon1 className="account-icon" />
                    </div>
                    {renderInvitedList(item.invited_contacts).count > 0 && (
                      <div className="invited-count">
                        {renderInvitedList(item.invited_contacts).count}
                      </div>
                    )}
                    <div className="invited-list">
                      {
                        renderInvitedList(item.invited_contacts)
                          .invitedContactsData
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    );
  }, [buttonStatus]);

  const headerSection = () => {
    return (
      <div className="schedule-header-newui">
        <div className="schedule-title-newui">
          <div className="schedule-text-newui">
            {openSubMenu ? (
              <div className="hub-schedule-header">
                <div className="hub-icon">
                  <NewScheduleIcon className="hub-schedule-icon" />
                </div>
                <div className="hub-schedule-title">{trans("SCHEDULE")}</div>
              </div>
            ) : (
              trans("MYSCHEDULE")
            )}
          </div>
        </div>
      </div>
    );
  };

  const render = (
    <div className="list-schedule-container-newui page-content-wrapper">
      {headerSection()}
      <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
        {renderScheduleList()}
      </div>
    </div>
  );

  return onlyContent ? (
    render
  ) : (
    <MainPage
      hasSider={openSubMenu ? false : true}
      header={header}
      noModals={!!openSubMenu}
    >
      {render}
    </MainPage>
  );
};

export default ListSchedule;
